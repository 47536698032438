import 'assets/common/scss/index'
import 'assets/images'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import IMask from 'imask';
import A11yDialog from 'a11y-dialog';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

require("trix")
require("@rails/actiontext")

document.addEventListener('turbolinks:load', function() {
  console.log('[common]');

  let add_fields = document.querySelector('.add_fields')

  if(add_fields !== null && typeof add_fields !== "undefined") {
    add_fields.addEventListener('click', function(e) {
      let el = e.target
      let time = new Date().getTime();
      let fields = document.querySelector('.fields')
      let regexp = new RegExp(el.dataset.id, 'g')

      fields.insertAdjacentHTML('beforeend', el.dataset.fields.replace(regexp, time));

      return e.preventDefault()
    })
  }

  document.querySelectorAll('input[data-mask="cpf"]').forEach((el) => {
    new IMask(el, {
      mask: '000.000.000-00'
    });
  })

  document.querySelectorAll('input[data-mask="cnpj"]').forEach((el) => {
    new IMask(el, {
      mask: '00.000.000/0000-00'
    });
  })

  document.querySelectorAll('input[data-mask="cnpjcpf"]').forEach((el) => {
    new IMask(el, {
      mask: [
        {
          mask: '000.000.000-00'
        },
        {
          mask: '00.000.000/0000-00'
        }
      ]
    });
  })

  document.querySelectorAll('input[data-mask="cep"]').forEach((el) => {
    new IMask(el, {
      mask: '00000-000'
    });
  })

  document.querySelectorAll('input[data-mask="telephone"]').forEach((el) => {
    new IMask(el, {
      mask: [
        {
          mask: '(00) 0000-0000'
        },
        {
          mask: '(00) 00000-0000'
        }
      ]
    });
  })

  document.querySelectorAll('input[data-mask="currency"]').forEach((el) => {
    new IMask(el, {
      mask: Number,  // enable number mask
      scale: 2,  // digits after point, 0 for integers
      signed: false,  // disallow negative
      thousandsSeparator: '.',  // any single char
      padFractionalZeros: true,  // if true, then pads zeros at end to the length of scale
      normalizeZeros: true,  // appends or removes zeros at ends
      radix: ',',  // fractional delimiter
      mapToRadix: ['.'],
      min: 1.00,
      max: 9999.99
    });
  })

  document.querySelectorAll('.dialog-container').forEach((el) => {
    const dialog = new A11yDialog(el)
  });
})